<template>
  <website>
    <div class="d-flex flex-column justify-center align-center my-10">
      <v-card
        :dark="theme.website.signing.dark"
        :light="theme.website.signing.light"
        :color="theme.website.signing.color"
        max-width="820px"
        min-width="60%"
      >
        <signin-form
          :header-class="theme.website.signing.header"
          :body-class="theme.website.signing.color"
          :actions-class="theme.website.signing.footer"
          @success="onSigninSuccess"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <span class="caption me-3">{{ $t('components.website.signin.signupMessage') }}</span>
          <v-btn color="secondary" to="signup">{{ $t('components.website.signin.signup') }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </website>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import SigninForm from '../components/Auth/SigninForm.vue'
  import Website from '../templates/Website/Website.vue'

  export default {
    name: 'ModenaSignin',
    components: { SigninForm, Website },
    mixins: [
      Themeable,
    ],
    methods: {
      onSigninSuccess () {
        this.$router.replace('/')
      },
    },
  }
</script>
